/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   1.0
 * @version 1.0 Initial Release
 */

import React, { Component } from 'react'
import icon from './bitbucket.svg'

class Bitbucket extends Component {
  render () {
    return (
      <img
        src={ icon }
        alt="Bitbucket"
        className="icon bitbucket"
        style={this.props.style}
      />
    )
  }
}

export default Bitbucket
