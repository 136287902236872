/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'

import Stack from './Stack'
import Swipe from './Swipe'
import Pencil from './Pencil'
import Puzzle from './Puzzle'
import GrapghQl from './GraphQl'
import MSSql from './MSSql'
import Json from './Json'
import Yaml from './Yaml'
import PHPUnit from './PHPUnit'
import RSpec from './RSpec'
import ESLint from './ESLint'
import Webpack from './Webpack'
import PHPComposer from './PHPComposer'
import Npm from './Npm'
import Magento from './Magento'
import Gatsby from './Gatsby'
import Jekyll from './Jekyll'
import RubyGems from './RubyGems'
import Babel from './Babel'
import Bitbucket from './Bitbucket'
import Kubernetes from './Kubernetes'
import GoogleCloud from './GoogleCloud'
import Atlassian from './Atlassian'
import Slack from './Slack'
import DigitalOcean from './DigitalOcean'
import RaspberryPi from './RaspberryPi'
import PhpStorm from './PhpStorm'
import RubyMine from './RubyMine'
import PyCharm from './PyCharm'
import IntelliJIdea from './IntelliJIdea'
import VisualStudioCode from './VisualStudioCode'
import Vagrant from './Vagrant'
import Mqtt from './Mqtt'
import LightBulb from './LightBulb'
import Tools from './Tools'
import LiftOff from './LiftOff'
import Scale from './Scale'
import Mailbox from './Mailbox'

class Icon extends Component {
  render () {
    const { type, ...props } = this.props

    let icon

    switch (type) {
    case 'Stack':
      icon = Stack
      break
    case 'Swipe':
      icon = Swipe
      break
    case 'Pencil' :
      icon = Pencil
      break
    case 'Puzzle' :
      icon = Puzzle
      break
    case 'GraphQl':
      icon = GrapghQl
      break
    case 'MSSql':
      icon = MSSql
      break
    case 'Json':
      icon = Json
      break
    case 'Yaml':
      icon = Yaml
      break
    case 'PHPUnit':
      icon = PHPUnit
      break
    case 'RSpec':
      icon = RSpec
      break
    case 'ESLint':
      icon = ESLint
      break
    case 'Webpack':
      icon = Webpack
      break
    case 'PHPComposer':
      icon = PHPComposer
      break
    case 'Npm':
      icon = Npm
      break
    case 'Magento':
      icon = Magento
      break
    case 'Gatsby':
      icon = Gatsby
      break
    case 'Jekyll':
      icon = Jekyll
      break
    case 'RubyGems':
      icon = RubyGems
      break
    case 'Babel':
      icon = Babel
      break
    case 'Bitbucket':
      icon = Bitbucket
      break
    case 'Kubernetes':
      icon = Kubernetes
      break
    case 'GoogleCloud':
      icon = GoogleCloud
      break
    case 'Atlassian':
      icon = Atlassian
      break
    case 'Slack':
      icon = Slack
      break
    case 'DigitalOcean':
      icon = DigitalOcean
      break
    case 'RaspberryPi':
      icon = RaspberryPi
      break
    case 'PhpStorm':
      icon = PhpStorm
      break
    case 'RubyMine':
      icon = RubyMine
      break
    case 'PyCharm':
      icon = PyCharm
      break
    case 'IntelliJIdea':
      icon = IntelliJIdea
      break
    case 'VisualStudioCode':
      icon = VisualStudioCode
      break
    case 'Vagrant':
      icon = Vagrant
      break
    case 'Mqtt':
      icon = Mqtt
      break
    case 'LightBulb':
      icon = LightBulb
      break
    case 'Tools':
      icon = Tools
      break
    case 'LiftOff':
      icon = LiftOff
      break
    case 'Scale':
      icon = Scale
      break
    case 'Mailbox':
      icon = Mailbox
      break
    }

    return (
      React.createElement(icon, { ...props })
    )
  }
}

export default Icon
