/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   1.0
 * @version 1.0 Initial Release
 */

import React, { Component } from 'react'
import icon from './tools.svg'

class Tools extends Component {
  render () {
    return (
      <div className={` icon ${ this.props.className }`}>
        <img
          src={ icon }
          alt="Tools icon"
        />
      </div>
    )
  }
}

export default Tools
