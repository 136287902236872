/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import icon from './icon.svg'

class Mailbox extends Component {
  render () {
    return (
      <div className={`icon ${ this.props.className }`}>
        <img src={icon} alt="Mailbox icon"/>
      </div>
    )
  }
}

export default Mailbox
